<template>
  <div class="Structure">
     预留
    <!-- <iframe id="container" frameborder="no" style="width:100%;height:100%;" src="./static/gxjk-demage/BCM.v.1.2_cm.html"></iframe> -->
  </div>
</template>
<script>

export default {
  name: 'Structure',
  data(){
      return{

      }
  },
  //创建
  created(){

  },

  //安装
  mounted(){

  },
  
  //方法
  methods:{

  }


}
</script>
<style  scoped lang="scss">
  .Structure{
    width: 100%;
    height: 100%;
  }

</style>